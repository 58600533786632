<template>
    <Icon v-if="inline" :icon="activityIcon" class="inline"
          :class="{ 'small': small }"/>

    <Icon v-else :icon="activityIcon" class="d-block"
          :class="{ 'small': small }" />

</template>

<script setup lang="ts">
const props = defineProps<{
    type: IActivityType;
    small?: boolean;
    inline?: boolean;
}>();

const activityIcon = computed(() => {
    switch (props.type.id) {
        case 1:
            return 'location_city';
            break;
        case 2:
            return 'phone_callback';
            break;
        case 3:
            return 'call';
            break;
        case 4:
            return 'unknown_med';
            break;
        case 5:
            return 'campaign';
            break;
        case 6:
            return 'email';
            break;
        case 7:
            return 'video_call';
            break;
        case 8:
            return 'sms';
            break;
        case 9:
            return 'festival';
            break;
        case 10:
            return 'webhook';
            break;
        case 11:
            return 'call_quality';
            break;
        default:
            return 'vital_signs';
    }
})
</script>
